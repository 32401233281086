import {
  DailySubjectReport,
  DeviceSessionIssue,
  SessionStatus,
  Study,
  SubjectSummary,
  useGetManyDailySubjectReportsQuery,
  useResolveSessionIssueMutation,
  useUpdateSubjectMutation,
} from "generated/graphql";
import Caption from "components/text/Caption";
import styled from "styled-components";
import theme from "lib/theme";
import moment from "moment";
import { toNumber } from "lodash";
import Chart2 from "components/common/SessionsTable/ExpandedRow/Chart2";
import sessionsIssueHashMap from "lib/helpers/sessionsIssueHashMap";
import {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import PrimaryButton from "components/common/Button";
import SwitchInput from "components/inputs/SwitchInput";
import AdminSessionModal from "components/modals/SubjectSessions";
import { Checkbox, Divider, message } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import IconChartCard from "lib/media/iconChartCard";
import { gql, useApolloClient } from "@apollo/client";
import { BaseCard } from "components/tables/AnalysisSessionTable/card-elements";
import useUrlChange from "lib/hooks/useUrlChange";
import {
  AbstractCheckboxProps,
  CheckboxChangeEvent,
} from "antd/lib/checkbox/Checkbox";

const IssuesContainer = styled.div`
  width: 100%;
  height: 180px;
  overflow-y: scroll;
  border-radius: 5px;
  box-shadow: inset 0 -16px 10px -10px rgba(0, 0, 0, 0.03);
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;
const StatusButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const SessionIssue = ({
  sessionIssues,
  index,
  onUploadIdClick,
}: {
  sessionIssues: any;
  index: number;
  onUploadIdClick: any;
}) => {
  const title = useMemo(() => `Issue ${index + 1}`, [index]);
  return (
    <div
      style={{
        padding: "8px",
      }}
    >
      <Caption
        style={{ marginBottom: 2, marginTop: 4, color: theme.colors.red4 }}
      >
        <strong>{title}</strong>
      </Caption>

      <div
        key={index}
        style={{
          borderBottom: `1px solid ${theme.colors.neutral9}`,
          paddingBottom: 8,
          paddingTop: 8,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 2,
          }}
        >
          <Caption style={{ marginBottom: 4 }}>
            <strong>Type: </strong>{" "}
            <span style={{ color: theme.colors.neutral5 }}>Session Issues</span>
          </Caption>
        </div>
        <Caption style={{ marginBottom: 4 }}>
          <strong>Count: </strong>
          <span style={{ color: theme.colors.neutral5 }}>
            {sessionIssues?.length}
          </span>
        </Caption>
        <div
          style={{
            width: "100%",
            display: "flex",
            marginTop: 16,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <HeadingRowCustom
            style={{
              width: "60%",
              textAlign: "left",
              justifyContent: "start",
            }}
          >
            <Caption style={{ marginBottom: 6 }}>
              <strong>UploadID</strong>
            </Caption>
          </HeadingRowCustom>
          <HeadingRowCustom
            style={{
              width: "20%",
              textAlign: "left",
              justifyContent: "start",
            }}
          >
            <Caption style={{ marginBottom: 6 }}>
              <strong>Status</strong>
            </Caption>
          </HeadingRowCustom>
          <HeadingRowCustom
            style={{
              width: "20%",
              textAlign: "left",
              justifyContent: "start",
            }}
          >
            <Caption style={{ marginBottom: 6 }}>
              <strong>Issue Type</strong>
            </Caption>
          </HeadingRowCustom>
        </div>
        {sessionIssues?.map((value: any) => (
          <div
            key={value?.id}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <HeadingRowCustom
              style={{
                width: "60%",
                textAlign: "left",
                justifyContent: "start",
              }}
            >
              <Caption
                onClick={onUploadIdClick}
                style={{
                  marginBottom: 2,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                color={"#127FBF"}
              >
                {value?.uploadId}
              </Caption>
            </HeadingRowCustom>
            <HeadingRowCustom
              style={{
                width: "20%",
                textAlign: "left",
                justifyContent: "start",
              }}
            >
              {value?.resolved ? "Resolved" : "Unresolved"}{" "}
            </HeadingRowCustom>
            <HeadingRowCustom
              style={{
                width: "20%",
                textAlign: "left",
                justifyContent: "start",
              }}
            >
              {value?.issueType && sessionsIssueHashMap[value?.issueType]}{" "}
            </HeadingRowCustom>
          </div>
        ))}
      </div>
    </div>
  );
};

const IssuesArea = ({
  record,
  index,
  clientView,
  reloadQuery,
}: {
  record: DeviceSessionIssue;
  index: number;
  clientView: boolean | undefined;
  reloadQuery?: any;
}) => {
  const title = useMemo(() => `Issue ${index + 1}`, [index]);
  const [resolved, setResolved] = useState(record.resolved);
  const [mutate] = useResolveSessionIssueMutation();

  const handleResolved: AbstractCheckboxProps<CheckboxChangeEvent>["onChange"] =
    useCallback(
      (e: CheckboxChangeEvent) => {
        const { checked } = e.target;
        if (
          typeof record.id === "string" &&
          typeof record.deviceSessionId === "string"
        ) {
          mutate({
            variables: {
              input: {
                sessionIssueId: record.id,
                sessionId: record.deviceSessionId,
                resolved: checked,
              },
            },
          }).then(() => {
            setResolved(checked);
            reloadQuery && reloadQuery();
          });
        }
      },
      [mutate, record.id, record.deviceSessionId]
    );

  return (
    <div
      style={
        resolved
          ? {
              padding: "8px",
              backgroundColor: "rgba(209, 209, 209, 0.4)",
            }
          : {
              padding: "8px",
            }
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Caption
          style={
            resolved
              ? {
                  opacity: 0.6,
                  marginBottom: 2,
                  marginTop: 4,
                  color: clientView ? theme.colors.black : theme.colors.red4,
                }
              : {
                  marginBottom: 2,
                  marginTop: 4,
                  color: clientView ? theme.colors.black : theme.colors.red4,
                }
          }
        >
          <strong>{title}:</strong>{" "}
          <span style={{ color: theme.colors.neutral5 }}>
            {record?.issueType && sessionsIssueHashMap[record?.issueType]}
          </span>
        </Caption>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: 6,
          }}
        >
          {!clientView && (
            <Checkbox checked={resolved === true} onChange={handleResolved} />
          )}
          {!clientView ? (
            <Caption>
              <span style={{ color: theme.colors.neutral5 }}>
                Resolve{resolved ? "d" : ""}
              </span>
            </Caption>
          ) : (
            <Caption>
              <span style={{ color: theme.colors.neutral5 }}>
                {resolved ? "Resolved" : "Unresolved"}
              </span>
            </Caption>
          )}
        </div>
      </div>
      <div
        key={record?.id}
        style={
          resolved
            ? {
                opacity: 0.6,
                borderBottom: `1px solid ${theme.colors.neutral9}`,
                paddingBottom: 8,
                paddingTop: 8,
              }
            : {
                borderBottom: `1px solid ${theme.colors.neutral9}`,
                paddingBottom: 8,
                paddingTop: 8,
              }
        }
      >
        <Caption
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16,
            marginBottom: 4,
          }}
        >
          <div>
            <strong>Created: </strong>
            <span style={{ color: theme.colors.neutral5 }}>
              {moment(toNumber(record?.createdAt))?.format("MM/DD/YY")}
            </span>
          </div>
          <div>
            <strong>Created by: </strong>
            <span style={{ color: theme.colors.neutral5 }}>Justice Amoh</span>
          </div>
        </Caption>
        <Caption style={{ marginBottom: 4 }}>
          <strong> Description: </strong>
          <span style={{ color: theme.colors.neutral5 }}>
            {record?.description}
          </span>
        </Caption>
      </div>
    </div>
  );
};

const InfoArea = ({
  record,
  setSessionSummaryId,
  sessionSummaryId,
  clientView,
}: {
  record: SubjectSummary;
  setSessionSummaryId: any;
  sessionSummaryId?: string;
  clientView?: boolean;
}) => {
  const subjectStartDate = moment(record?.sessions?.[0]?.endDate);
  const subjectEndDate = moment(
    record?.sessions?.[record?.sessions?.length]?.endDate
  );

  let d: any = 0;
  // eslint-disable-next-line array-callback-return
  record?.sessions?.map((session) => {
    const momentStartDate = moment(session?.startDate);
    const momentEndDate = moment(session?.endDate);
    // Breaking down date difference into time units
    d = d + Math.abs(momentEndDate.unix() - momentStartDate.unix()); // delta
  });

  let r: any = {};
  let s: any = {
    // years: 31536000,
    // months: 2592000,
    // weeks: 604800,
    d: 86400,
    h: 3600,
    m: 60,
    s: 1,
  };

  Object.keys(s).forEach((key) => {
    r[key] = Math.floor(d / s[key]);
    d -= r[key] * s[key];
  });

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        padding: "12px",
      }}
    >
      <Caption
        style={
          clientView
            ? { marginBottom: 3, display: "flex", gap: "8px" }
            : {
                marginBottom: 3,
                display: "flex",
                justifyContent: "space-between",
              }
        }
      >
        <strong>Study:</strong> {record?.study?.name}
      </Caption>
      <Caption
        style={
          clientView
            ? { marginBottom: 12, display: "flex", gap: "8px" }
            : {
                marginBottom: 12,
                display: "flex",
                justifyContent: "space-between",
              }
        }
      >
        <strong>Site:</strong> {record?.site?.name}
      </Caption>

      <Caption
        style={
          clientView
            ? { marginBottom: 3, display: "flex", gap: "8px" }
            : {
                marginBottom: 3,
                display: "flex",
                justifyContent: "space-between",
              }
        }
      >
        <strong>Subject Start:</strong>{" "}
        {subjectStartDate?.format("MM/DD/YY, hh:mm a")}
      </Caption>

      <Caption
        style={
          clientView
            ? { marginBottom: 3, display: "flex", gap: "17px" }
            : {
                marginBottom: 3,
                display: "flex",
                justifyContent: "space-between",
              }
        }
      >
        <strong>Subject End:</strong>{" "}
        {subjectEndDate?.format("MM/DD/YY, hh:mm a")}
      </Caption>
      <Caption
        style={
          clientView
            ? { marginBottom: 3, display: "flex", gap: "8px" }
            : {
                marginBottom: 3,
                display: "flex",
                justifyContent: "space-between",
              }
        }
      >
        {/* //should be sum of durations of all sessions */}
        <strong>Monitoring Duration:</strong>{" "}
        {r?.d && r?.d > 0 ? `${r?.d}d` : ""}{" "}
        {r?.h && r?.h > 0 ? `${r?.h}h` : ""}{" "}
        {r?.m && r?.m > 0 ? `${r?.m}m` : ""}{" "}
        {r?.s && r?.s > 0 ? `${r?.s}s` : ""}
      </Caption>
      <Caption
        style={
          clientView
            ? { marginBottom: 3, display: "flex", gap: "8px" }
            : {
                marginBottom: 3,
                display: "flex",
                justifyContent: "space-between",
              }
        }
      >
        <strong>Session Count:</strong> {record?.sessions?.length}
      </Caption>
    </div>
  );
};

const HeadingRowCustom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Row = styled.div`
  min-height: 56px;
  display: flex;
  align-items: center;
`;

const StyledSwitchInput = styled(SwitchInput)`
  width: 100px;
  height: 26px;

  div {
    height: 19px;
    width: 19px;
    ::before {
      border-radius: 100%;
    }
  }

  &:not(.ant-switch-checked) {
    background: #d9d9d9;
    div {
      padding: 11px;
      ::before {
        color: #747168;
        background-color: #747168;
      }
    }
    span {
      color: #747168;
    }
  }

  &.ant-switch-checked {
    background: #50acde;
    border: 1px solid #d9d9d9;
    div {
      ::before {
        background-color: #d9d9d9;
      }
    }
    span {
      color: #feffff;
    }
  }
`;

interface Props {
  clientView?: boolean;
  openByDefault?: string | null;
  record: SubjectSummary;
  reloadQuery: () => void;
  createIssueButton: ReactElement<any, any>;
}

export default function ExpandedRow({
  record,
  clientView,
  openByDefault,
  createIssueButton,
  reloadQuery = () => void 35,
}: Props) {
  const [, setSessionSummaryId] = useState(record?.id);
  const [sessionUploadId, setSessionUploadId] = useState("");
  const [isSessionModalOpen, setIsSessionModalOpen] = useState(false);
  const { onUrlChange } = useUrlChange();
  const [buttonToggle, setButtonToggle] = useState(
    record.status?.key === "Completed"
  );
  const { data: reports, refetch: refetchReports } =
    useGetManyDailySubjectReportsQuery({
      variables: { subjectId: record?.subjectId as string },
    });
  useEffect(() => {
    if (openByDefault === record.id) {
      setIsSessionModalOpen(true);
    }
  }, [openByDefault, record]);

  const mapRecord =
    record?.sessions?.reduce?.((acc, key) => {
      const issues = key?.issues
        ? key?.issues?.map((singularIssue) => {
            return { ...singularIssue, uploadId: key?.uploadId };
          })
        : [];
      return [...acc, ...issues];
    }, [] as DeviceSessionIssue[]) ?? [];
  const [updateSubject] = useUpdateSubjectMutation();
  const apolloClient = useApolloClient();

  const handleToggle = useCallback(
    (isCompleted: boolean) => {
      (async () => {
        setButtonToggle(isCompleted);

        const previousStatus = record?.status?.key;

        apolloClient.writeFragment({
          id: `SubjectSummary:${record?.id ?? ""}`,
          fragment: gql`
            fragment SubjectStatus on SubjectSummary {
              status
            }
          `,
          data: {
            status: {
              key: isCompleted ? "Completed" : previousStatus,
            },
          },
        });

        try {
          await updateSubject({
            variables: {
              input: {
                id: record?.id ?? "",
                isCompleted,
              },
            },
          });
          reloadQuery();
        } catch (error) {
          apolloClient.writeFragment({
            id: `SessionSummary:${record?.id ?? ""}`,
            fragment: gql`
              fragment SubjectStatus on SubjectSummary {
                status
              }
            `,
            data: {
              status: {
                key: previousStatus,
              },
            },
          });
          message.error(`There was an error updating the subject.`);
        }
      })();
    },
    [record?.status?.key, record?.id, apolloClient, updateSubject, reloadQuery]
  );

  const mapRecordStatus =
    record?.sessions?.reduce?.((acc, key) => {
      if (!key?.selected) {
        return [...acc];
      }

      const status = key?.status ?? ({} as SessionStatus);
      return [...acc, status];
    }, [] as SessionStatus[]) ?? [];

  const statusOptions = [
    "Available",
    "AnalysisPending",
    "AnalysisInitiated",
    "UnderReview",
    "Reviewed",
    "Completed",
  ];
  let count = [0, 0, 0, 0, 0, 0];
  mapRecordStatus.forEach((record) => {
    switch (record.key) {
      case statusOptions[0]:
        count[0]++;
        break;
      case statusOptions[1]:
        count[1]++;
        break;
      case statusOptions[2]:
        count[2]++;
        break;
      case statusOptions[3]:
        count[3]++;
        break;
      case statusOptions[4]:
        count[4]++;
        break;
      case statusOptions[5]:
        count[5]++;
        break;
      default:
        break;
    }
  });
  interface RecordCount {
    key: string;
    count: number;
  }

  const renderLabel = (value: any) => {
    switch (value?.status?.key) {
      case "Available":
        return (
          <MinusCircleOutlined
            style={{
              color: theme.colors?.gray2,
              fontSize: 20,
            }}
          />
        );
      case "AnalysisPending":
        return <IconChartCard pieChartQtr />;
      case "AnalysisInitiated":
        return <IconChartCard pieChartHalf />;
      case "UnderReview":
        return <IconChartCard underReview />;
      case "Reviewed":
        return <IconChartCard reviewed />;
      case "Completed":
        return <IconChartCard completed />;

      default:
        return <div />;
    }
  };

  const recordCount: RecordCount[] = count
    .map((num, index) => ({
      key: statusOptions[index],
      count: num,
    }))
    .filter(({ count }) => count > 0);

  const closeModalCallback = useCallback(() => {
    reloadQuery();
    setIsSessionModalOpen(false);
  }, [reloadQuery, setIsSessionModalOpen]);

  return (
    <div
      style={{
        paddingBottom: 0,
        paddingTop: 10,
        border: 0,
        background: "transparent",
        // height: record?.issues?.[0] ? 700 : 600,
      }}
    >
      <Row
        style={
          !clientView
            ? { height: 200, justifyContent: "space-between", gap: 12 }
            : { height: 200, justifyContent: "space-around" }
        }
      >
        <div style={!clientView ? { width: "28%" } : { width: "43.5%" }}>
          <BaseCard title="Subject Info" titleColor={theme.colors.gray1}>
            <InfoArea
              record={record}
              setSessionSummaryId={setSessionSummaryId}
              clientView={clientView}
            />
          </BaseCard>
        </div>
        {/* {!clientView && (
          <Divider
            type="vertical"
            style={{
              height: "200px",
              width: "28.5px",
            }}
          />
        )} */}
        {!clientView && (
          <div style={{ width: "26%" }}>
            <BaseCard
              title={`Subject Sessions (${
                record?.sessions?.length ? record?.sessions?.length : 0
              })`}
              titleColor={theme.colors.gray1}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{ width: "100%", height: "140px", overflow: "auto" }}
                >
                  {record?.sessions?.map((value) => (
                    <div
                      key={value?.id}
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "8px",
                      }}
                    >
                      <HeadingRowCustom style={{ width: "100%", gap: "8px" }}>
                        {renderLabel(value)}
                        <Caption
                          onClick={() => {
                            onUrlChange({ sessionUploadId: value?.id });
                            setSessionUploadId(value?.id ? value?.id : "");
                            setIsSessionModalOpen(true);
                          }}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            fontSize: "12px",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          color={"#127FBF"}
                        >
                          {value?.uploadId}
                        </Caption>
                      </HeadingRowCustom>
                    </div>
                  ))}
                </div>
                <Divider
                  type="horizontal"
                  style={{
                    height: "1px",
                    width: "100%",
                    margin: "3px 0px",
                  }}
                />
                <StatusButtonsContainer>
                  <ButtonsContainer>
                    {!clientView && (
                      <>
                        <PrimaryButton
                          style={{
                            width: 100,
                            height: 30,
                            fontSize: "12px !important",
                            padding: 5,
                          }}
                          smallSize
                          onClick={() => {
                            setIsSessionModalOpen(true);
                            onUrlChange({ sessionSubject: record.id });
                          }}
                          select
                        >
                          Manage
                        </PrimaryButton>
                        <StyledSwitchInput
                          onChange={(evt) => handleToggle(evt.valueOf())}
                          checkedChildren="Completed"
                          unCheckedChildren="Incomplete"
                          checked={buttonToggle}
                          disabled={
                            !(
                              recordCount.length === 1 &&
                              recordCount[0].key === statusOptions[4]
                            )
                          }
                        />
                      </>
                    )}
                  </ButtonsContainer>
                </StatusButtonsContainer>
              </div>
            </BaseCard>
          </div>
        )}
        {isSessionModalOpen && (
          <AdminSessionModal
            refetchReports={refetchReports}
            dataPoints={
              reports?.getManyDailySubjectReports as DailySubjectReport[]
            }
            close={closeModalCallback}
            open={isSessionModalOpen}
            sessionUploadId={sessionUploadId ? sessionUploadId : ""}
            summary={record}
            reloadQuery={reloadQuery}
          />
        )}
        {/* <Divider
          type="vertical"
          style={{
            height: "200px",
          }}
        /> */}
        <div style={!clientView ? { width: "48%" } : { width: "43.5%" }}>
          <BaseCard
            createIssueButton={createIssueButton}
            title={
              clientView
                ? `Issues (${
                    mapRecord?.concat(
                      record?.issues?.filter(
                        (issue) => !issue?.resolved
                      ) as DeviceSessionIssue
                    )?.length
                  })`
                : `Issues (${
                    (record?.issues
                      ? record?.issues?.filter((issue) => !issue?.resolved)
                          ?.length
                      : 0) + (mapRecord ? 1 : 0)
                  })`
            }
            titleColor={theme.colors.red4}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <IssuesContainer>
                {!clientView &&
                  record?.issues &&
                  [...record?.issues]
                    ?.sort((a, b) => {
                      if (a.resolved === true && b.resolved === true) return 0;
                      else if (b.resolved === true) return -1;
                      return 1;
                    })
                    ?.map((item, index) => {
                      if (!item?.resolved)
                        return (
                          <IssuesArea
                            key={item.id}
                            // reloadQuery={reloadQuery}
                            record={item as DeviceSessionIssue}
                            index={index}
                            clientView={clientView}
                          />
                        );
                    })}

                {clientView &&
                  mapRecord
                    .concat(record.issues as DeviceSessionIssue)
                    .map((item, index) => {
                      return (
                        <IssuesArea
                          key={item.id}
                          // reloadQuery={reloadQuery}
                          record={item as DeviceSessionIssue}
                          index={index}
                          clientView={clientView}
                        />
                      );
                    })}

                {!clientView && record?.issues && record?.issues?.length > 0 && (
                  <SessionIssue
                    onUploadIdClick={(id: string) => {
                      onUrlChange({ sessionUploadId: id });
                      setSessionUploadId(id ? id : "");
                      setIsSessionModalOpen(true);
                    }}
                    key={record.id}
                    index={record?.issues ? record?.issues?.length : 0}
                    sessionIssues={mapRecord}
                  />
                )}

                {!clientView &&
                  record?.issues &&
                  [...record?.issues]
                    ?.sort((a, b) => {
                      if (a.resolved === true && b.resolved === true) return 0;
                      else if (b.resolved === true) return -1;
                      return 1;
                    })
                    ?.map((item, index) => {
                      if (item?.resolved)
                        return (
                          <IssuesArea
                            key={item.id}
                            reloadQuery={reloadQuery}
                            record={item as DeviceSessionIssue}
                            index={index}
                            clientView={clientView}
                          />
                        );
                    })}
              </IssuesContainer>
            </div>
          </BaseCard>
        </div>
      </Row>
      <Divider
        style={
          clientView
            ? { marginTop: 24, marginBottom: 12 }
            : { marginTop: 24, marginBottom: 4 }
        }
      />
      <Chart2
        clientView={clientView}
        dataPoints={reports?.getManyDailySubjectReports as DailySubjectReport[]}
        studyData={record?.study as Study}
        subjectId={record?.subjectId as string}
        adminView={!clientView}
      />
    </div>
  );
}
